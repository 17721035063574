:root {
  --primary-color: #385542;
}
.btn-primary {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
.btn-primary:hover {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  border-color: var(--primary-color) !important;
}
.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(56, 85, 66, 0.5) !important;
}
.btn-outline-primary {
  background-color: rgba(255, 255, 255, 0.9);
  color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
.btn-outline-primary:hover {
  background-color: var(--primary-color) !important;
  color: #fff !important;
  border-color: var(--primary-color) !important;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  border-color: var(--primary-color) !important;
}
.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(56, 85, 66, 0.5) !important;
}
.cardheader {
  background-color: var(--primary-color);
  padding: 12px 24px 10px 24px;
  cursor: pointer;
}
.cardheader h4 {
  color: rgba(255, 255, 255, 0.55);
  margin-bottom: 0px;
  font-size: 14px;
}
.cardheader h2 {
  color: #fff;
  margin-bottom: 0px;
}
.cardheader h3 {
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 0px;
}
.cardcontents {
  padding: 12px 16px 10px 16px;
}
.App {
  text-align: left;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#sidebar {
  box-shadow: 0 0 0 1px rgba(49, 49, 93, 0.03),
    0 2px 5px 0 rgba(49, 49, 93, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
}
.sidebar {
  position: fixed;
  z-index: 1000;
  width: 36%;
  height: 96%;
  background-color: #fff;
  padding: 0px;
  border-radius: 8px;
  top: 1%;
  right: 1%;
  overflow-y: scroll;
  transition: all 0.5s ease;
}
.input-group-text {
  min-width: 48px;
}
.input-group {
  margin-top: 6px;
}
.expanded-results {
  position: fixed;
  z-index: 1000;
  width: 66%;
  height: 96%;
  background-color: #fff;
  padding: 0px;
  border-radius: 8px;
  top: 1%;
  right: 1%;
  overflow-y: scroll;
  transition: all 0.5s ease;
}

.layers-container {
  position: fixed;
  top: 5px;
  left: 50px;
  z-index: 1000;
  max-width: 180px;
}

.layers {
  background-color: #fff;
  border-radius: 8px;
  margin-top: 7px;
  max-height: 300px;
  overflow-y: scroll;
  box-shadow: 0 0 0 1px rgba(49, 49, 93, 0.03),
    0 2px 5px 0 rgba(49, 49, 93, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
}
.layers-toggle {
  top: 1%;
  left: 18%;
  z-index: 1000;
}

.esriLegendServiceLabel {
  font-weight: bold;
}
.toggle-buttons {
  position: fixed;
  padding: 12px 7px 12px 7px;
  border-radius: 8px;
  left: 0.5%;
  top: 25%;
  z-index: 1000;
  width: 210px;
  border: 1px solid green;
  background-color: rgba(229, 229, 229, 0.4);
}
.toggle-buttons svg {
  margin-left: 8px;
}

.input-result-toggle .pagination-lg {
  margin-bottom: 0px;
}
.layers p {
  color: #fff;
  margin: 0;
}

.chart-container {
  display: flex;
}

.chart {
  flex: 1 1 33%;
}

.tooltip-sidebar {
  z-index: 3001;
}

.years-pagination {
  overflow-x: scroll;
}

.years-page {
  height: 100%;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(56, 85, 66, 0.5) !important;
}
.progress-bar {
  background-color: var(--primary-color) !important;
}
.results-sidebar .text-primary {
  color: var(--primary-color) !important;
}
.page-link {
  color: var(--primary-color) !important;
}
.active .page-link {
  color: #fff !important;
}

.leaflet-div-icon {
  background: transparent;
  border: none;
}
.leaflet-marker-icon .svg-inline--fa {
  color: var(--primary-color);
}


input[type='number'] {
  -moz-appearance: textfield;
}

    ::before,
    ::after {
      --tw-content: '';
    }

    html,
    :host {
      line-height: 1.5;
     
      -webkit-text-size-adjust: 100%;
     
     
      tab-size: 4;
     
      font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
     
      font-feature-settings: normal;
     
      font-variation-settings: normal;
     
      -webkit-tap-highlight-color: transparent;
     
    }

   

    body {
      margin: 0;
     
      line-height: inherit;
     
    }

   

    hr {
      height: 0;
     
      color: inherit;
     
      border-top-width: 1px;
     
    }

   

    abbr:where([title]) {
      -webkit-text-decoration: underline dotted;
      text-decoration: underline dotted;
    }

   

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: inherit;
      font-weight: inherit;
    }

   

    a {
      color: inherit;
      text-decoration: inherit;
    }

   

    b,
    strong {
      font-weight: bolder;
    }

   

    code,
    kbd,
    samp,
    pre {
      font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
     
      font-feature-settings: normal;
     
      font-variation-settings: normal;
     
      font-size: 1em;
     
    }

   

    small {
      font-size: 80%;
    }

   

    sub,
    sup {
      font-size: 75%;
      line-height: 0;
      position: relative;
      vertical-align: baseline;
    }

    sub {
      bottom: -0.25em;
    }

    sup {
      top: -0.5em;
    }

   

    table {
      text-indent: 0;
     
      border-color: inherit;
     
      border-collapse: collapse;
     
    }

   

    button,
    input,
    optgroup,
    select,
    textarea {
      font-family: inherit;
     
      font-feature-settings: inherit;
     
      font-variation-settings: inherit;
     
      font-size: 100%;
     
      font-weight: inherit;
     
      line-height: inherit;
     
      color: inherit;
     
      margin: 0;
     
      padding: 0;
     
    }

   

    button,
    select {
      text-transform: none;
    }

   

    button,
    [type='button'],
    [type='reset'],
    [type='submit'] {
      -webkit-appearance: button;
     
      background-color: transparent;
     
      background-image: none;
     
    }

   

    :-moz-focusring {
      outline: auto;
    }

   

    :-moz-ui-invalid {
      box-shadow: none;
    }

   

    progress {
      vertical-align: baseline;
    }

   

    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      height: auto;
    }

   

    [type='search'] {
      -webkit-appearance: textfield;
     
      outline-offset: -2px;
     
    }

   

    ::-webkit-search-decoration {
      -webkit-appearance: none;
    }

   

    ::-webkit-file-upload-button {
      -webkit-appearance: button;
     
      font: inherit;
     
    }

   

    summary {
      display: list-item;
    }

   

    blockquote,
    dl,
    dd,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    hr,
    figure,
    p,
    pre {
      margin: 0;
    }

    fieldset {
      margin: 0;
      padding: 0;
    }

    legend {
      padding: 0;
    }

    ol,
    ul,
    menu {
      list-style: none;
      margin: 0;
      padding: 0;
    }

   
    dialog {
      padding: 0;
    }

   

    textarea {
      resize: vertical;
    }

   

    input::placeholder,
    textarea::placeholder {
      opacity: 1;
     
      color: #9ca3af;
     
    }

   

    button,
    [role="button"] {
      cursor: pointer;
    }

   
    :disabled {
      cursor: default;
    }

   

    img,
    svg,
    video,
    canvas,
    audio,
    iframe,
    embed,
    object {
      display: block;
     
      vertical-align: middle;
     
    }

   

    img,
    video {
      max-width: 100%;
      height: auto;
    }

   
    [hidden] {
      display: none;
    }

    *, ::before, ::after {
      --tw-border-spacing-x: 0;
      --tw-border-spacing-y: 0;
      --tw-translate-x: 0;
      --tw-translate-y: 0;
      --tw-rotate: 0;
      --tw-skew-x: 0;
      --tw-skew-y: 0;
      --tw-scale-x: 1;
      --tw-scale-y: 1;
      --tw-scroll-snap-strictness: proximity;
      --tw-ring-offset-width: 0px;
      --tw-ring-offset-color: #fff;
      --tw-ring-color: rgb(15 23 42 / 0.5);
      --tw-ring-offset-shadow: 0 0 #0000;
      --tw-ring-shadow: 0 0 #0000;
      --tw-shadow: 0 0 #0000;
      --tw-shadow-colored: 0 0 #0000;
    }

    ::backdrop {
      --tw-border-spacing-x: 0;
      --tw-border-spacing-y: 0;
      --tw-translate-x: 0;
      --tw-translate-y: 0;
      --tw-rotate: 0;
      --tw-skew-x: 0;
      --tw-skew-y: 0;
      --tw-scale-x: 1;
      --tw-scale-y: 1;
      --tw-scroll-snap-strictness: proximity;
      --tw-ring-offset-width: 0px;
      --tw-ring-offset-color: #fff;
      --tw-ring-color: rgb(15 23 42 / 0.5);
      --tw-ring-offset-shadow: 0 0 #0000;
      --tw-ring-shadow: 0 0 #0000;
      --tw-shadow: 0 0 #0000;
      --tw-shadow-colored: 0 0 #0000;
    }

    .container {
      width: 100%;
    }

    @media (min-width: 1481px) {

      .container {
        max-width: 1481px;
      }
    }

    @media (min-width: 1536px) {

      .container {
        max-width: 1536px;
      }
    }

    .sr-only {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border-width: 0;
    }

    .fixed {
      position: fixed;
    }

    .absolute {
      position: absolute;
    }

    .relative {
      position: relative;
    }

    .sticky {
      position: sticky;
    }

    .inset-0 {
      inset: 0px;
    }

    .bottom-0 {
      bottom: 0px;
    }

    .left-0 {
      left: 0px;
    }

    .right-0 {
      right: 0px;
    }

    .top-0 {
      top: 0px;
    }

    .top-50p {
      top: 50px;
    }

    .z-1 {
      z-index: 1;
    }

    .z-10 {
      z-index: 10;
    }

    .z-20 {
      z-index: 20;
    }

    .z-40 {
      z-index: 40;
    }

    .z-50 {
      z-index: 50;
    }

    .m-2 {
      margin: 0.5rem;
    }

    .m-4 {
      margin: 1rem;
    }

    .mx-0 {
      margin-left: 0px;
      margin-right: 0px;
    }

    .mx-1 {
      margin-left: 0.25rem;
      margin-right: 0.25rem;
    }

    .mx-8 {
      margin-left: 2rem;
      margin-right: 2rem;
    }

    .mx-auto {
      margin-left: auto;
      margin-right: auto;
    }

    .my-10 {
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
    }

    .my-1p {
      margin-top: 1px;
      margin-bottom: 1px;
    }

    .my-24 {
      margin-top: 6rem;
      margin-bottom: 6rem;
    }

    .my-4 {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    .my-8 {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    .my-\[4em\] {
      margin-top: 4em;
      margin-bottom: 4em;
    }

    .mb-10 {
      margin-bottom: 2.5rem;
    }

    .mb-16 {
      margin-bottom: 4rem;
    }

    .mb-1p {
      margin-bottom: 1px;
    }

    .mb-2 {
      margin-bottom: 0.5rem;
    }

    .mb-24 {
      margin-bottom: 6rem;
    }

    .mb-4 {
      margin-bottom: 1rem;
    }

    .mb-6 {
      margin-bottom: 1.5rem;
    }

    .mb-8 {
      margin-bottom: 2rem;
    }

    .mb-\[-1em\] {
      margin-bottom: -1em;
    }

    .mb-\[-2em\] {
      margin-bottom: -2em;
    }

    .me-2 {
      margin-inline-end: 0.5rem;
    }

    .me-3 {
      margin-inline-end: 0.75rem;
    }

    .ml-8 {
      margin-left: 2rem;
    }

    .ml-\[220px\] {
      margin-left: 220px;
    }

    .ml-auto {
      margin-left: auto;
    }

    .ms-auto {
      margin-inline-start: auto;
    }

    .mt-10 {
      margin-top: 2.5rem;
    }

    .mt-100p {
      margin-top: 100px;
    }

    .mt-12 {
      margin-top: 3rem;
    }

    .mt-14 {
      margin-top: 3.5rem;
    }

    .mt-16 {
      margin-top: 4rem;
    }

    .mt-180p {
      margin-top: 180px;
    }

    .mt-2 {
      margin-top: 0.5rem;
    }

    .mt-20 {
      margin-top: 5rem;
    }

    .mt-2p {
      margin-top: 2px;
    }

    .mt-3 {
      margin-top: 0.75rem;
    }

    .mt-30p {
      margin-top: 30px;
    }

    .mt-4 {
      margin-top: 1rem;
    }

    .mt-6 {
      margin-top: 1.5rem;
    }

    .mt-8 {
      margin-top: 2rem;
    }

    .block {
      display: block;
    }

    .inline {
      display: inline;
    }

    .flex {
      display: flex;
    }

    .inline-flex {
      display: inline-flex;
    }

    .table {
      display: table;
    }

    .grid {
      display: grid;
    }

    .h-0 {
      height: 0px;
    }

    .h-180p {
      height: 180px;
    }

    .h-0\.5 {
      height: 0.125rem;
    }

    .h-100p {
      height: 100px;
    }

    .h-150p {
      height: 150px;
    }

    .h-11 {
      height: 2.75rem;
    }

    .h-250p {
      height: 250px;
    }

    .h-3 {
      height: 0.75rem;
    }

    .h-30p {
      height: 30px;
    }

    .h-32 {
      height: 8rem;
    }

    .h-35p {
      height: 35px;
    }

    .h-4 {
      height: 1rem;
    }

    .h-40p {
      height: 40px;
    }

    .h-50p {
      height: 50px;
    }

    .h-550p {
      height: 550px;
    }

    .h-56 {
      height: 14rem;
    }

    .h-6 {
      height: 1.5rem;
    }

    .h-600p {
      height: 600px;
    }

    .h-60p {
      height: 60px;
    }

    .h-7 {
      height: 1.75rem;
    }

    .h-8 {
      height: 2rem;
    }

    .h-20 {
      height: 20rem;
    }

    .h-30per {
      height: 30%;
    }

    .h-40per {
      height: 40%;
    }

    .h-60per {
      height: 60%;
    }

    .h-70per {
      height: 70%;
    }

    .h-80per {
      height: 80%;
    }

    .h-85per {
      height: 85%;
    }

    .h-90per {
      height: 90%;
    }

    .h-9 {
      height: 2.25rem;
    }

    .h-\[95vh\] {
      height: 95vh;
    }

    .h-auto {
      height: auto;
    }

    .h-full {
      height: 100%;
    }

    .h-screen {
      height: 100vh;
    }

    .h-1\/2 {
      height: 50%;
    }

    .max-h-75per {
      max-height: 75%;
    }

    .max-h-80per {
      max-height: 80%;
    }

    .max-h-90per {
      max-height: 90%;
    }

    .max-h-200p {
      max-height: 200px;
    }

    .w-90p {
      width: 90px;
    }

    .w-100p {
      width: 100px;
    }

    .w-120p {
      width: 120px;
    }

    .w-150p {
      width: 150px;
    }

    .w-180p {
      width: 180px;
    }

    .w-200p {
      width: 200px;
    }

    .w-220p {
      width: 220px;
    }

    .w-3 {
      width: 0.75rem;
    }

    .w-30p {
      width: 30px;
    }

    .w-350p {
      width: 350px;
    }

    .w-35p {
      width: 35px;
    }

    .w-4 {
      width: 1rem;
    }

    .w-40p {
      width: 40px;
    }

    .w-50p {
      width: 50px;
    }

    .w-550p {
      width: 550px;
    }

    .w-400p {
      width: 400px;
    }

    .w-6 {
      width: 1.5rem;
    }

    .w-750p {
      width: 750px;
    }

    .w-8 {
      width: 2rem;
    }

    .w-12 {
      width: 4rem;
    }

    .w-80p {
      width: 80px;
    }

    .w-80per {
      width: 80%;
    }

    .w-90per {
      width: 90%;
    }

    .w-920p {
      width: 920px;
    }

    .w-auto {
      width: auto;
    }

    .w-full {
      width: 100%;
    }

    .w-screen {
      width: 100vw;
    }

    .w-1\/2 {
      width: 50%;
    }

    .max-w-200p {
      max-width: 200px;
    }

    .max-w-250p {
      max-width: 250px;
    }

    .max-w-500p {
      max-width: 500px;
    }

    .max-w-820p {
      max-width: 820px;
    }

    .max-w-1028p {
      max-width: 1028px;
    }

    .max-w-80per {
      max-width: 80%;
    }

    .min-w-200p {
      min-width: 200px;
    }

    .flex-1 {
      flex: 1 1 0%;
    }

    .flex-wrap {
      flex-wrap: wrap;
    }

    .flex-initial {
      flex: 0 1 auto;
    }

    .shrink-0 {
      flex-shrink: 0;
    }

    .basis-1\/2 {
      flex-basis: 50%;
    }

    .basis-1\/4 {
      flex-basis: 25%;
    }

    .transform {
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }

    @keyframes fadeInDrop {

      0% {
        opacity: 0;
        transform: translateY(-20px);
      }

      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .animate-fade-in-drop {
      animation: fadeInDrop 0.5s ease-in-out;
    }

    @keyframes pulse {

      50% {
        opacity: .5;
      }
    }

    .animate-pulse {
      animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    }

    @keyframes spin {

      to {
        transform: rotate(360deg);
      }
    }

    .animate-spin {
      animation: spin 1s linear infinite;
    }

    .cursor-not-allowed {
      cursor: not-allowed;
    }

    .cursor-pointer {
      cursor: pointer;
    }

    .resize {
      resize: both;
    }

    .grid-cols-1 {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .grid-cols-1-1\.5fr {
      grid-template-columns: 1fr 1.5fr;
    }

    .grid-cols-1\.5-1fr {
      grid-template-columns: 1.5fr 1fr;
    }

    .grid-cols-2 {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .grid-cols-3 {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .grid-cols-4 {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .grid-cols-3f-1f {
      grid-template-columns: 3fr 1fr;
    }

    .grid-rows-2 {
      grid-template-rows: repeat(2, minmax(0, 1fr));
    }

    .flex-row {
      flex-direction: row;
    }

    .flex-col {
      flex-direction: column;
    }

    .items-start {
      align-items: flex-start;
    }

    .items-end {
      align-items: flex-end;
    }

    .items-center {
      align-items: center;
    }

    .justify-center {
      justify-content: center;
    }

    .justify-between {
      justify-content: space-between;
    }

    .justify-around {
      justify-content: space-around;
    }

    .justify-evenly {
      justify-content: space-evenly;
    }

    .gap-8 {
      gap: 2rem;
    }
    
    .gap-16 {
      gap: 4rem;
    }

    .gap-24 {
      gap: 6rem;
    }

    .gap-x-10 {
      column-gap: 2.5rem;
    }

    .gap-x-12 {
      column-gap: 3rem;
    }

    .gap-x-16 {
      column-gap: 4rem;
    }

    .gap-x-2 {
      column-gap: 0.5rem;
    }

    .gap-x-4 {
      column-gap: 1rem;
    }

    .gap-x-6 {
      column-gap: 1.5rem;
    }

    .gap-x-7 {
      column-gap: 1.75rem;
    }

    .gap-x-8 {
      column-gap: 2rem;
    }

    .gap-x-\[4em\] {
      column-gap: 4em;
    }

    .gap-y-10 {
      row-gap: 2.5rem;
    }

    .gap-y-14 {
      row-gap: 3.5rem;
    }

    .gap-y-24 {
      row-gap: 6rem;
    }

    .gap-y-4 {
      row-gap: 1rem;
    }

    .gap-y-2 {
      row-gap: 0.5rem;
    }

    .gap-y-3 {
      row-gap: 0.75rem;
    }

    .gap-y-6 {
      row-gap: 1.5rem;
    }

    .underline {
      text-decoration: underline;
    }

    .-space-x-52 > :not([hidden]) ~ :not([hidden]) {
      --tw-space-x-reverse: 0;
      margin-right: calc(-13rem * var(--tw-space-x-reverse));
      margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse)));
    }

    .overflow-x-auto {
      overflow-x: auto;
    }

    .overflow-y-auto {
      overflow-y: auto;
    }

    .overflow-x-hidden {
      overflow-x: hidden;
    }

    .truncate {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .rounded {
      border-radius: 0.25rem;
    }

    .rounded-full {
      border-radius: 9999px;
    }

    .rounded-lg {
      border-radius: 0.5rem;
    }

    .rounded-xl {
      border-radius: 1rem;
    }

    .rounded-md {
      border-radius: 0.375rem;
    }

    .rounded-t {
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }

    .rounded-b {
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }

    .rounded-bl-md {
      border-bottom-left-radius: 0.375rem;
    }

    .border {
      border-width: 1px;
    }

    .border-1p {
      border-width: 1px;
    }

    .border-2p {
      border-width: 2px;
    }

    .border-3p {
      border-width: 3px;
    }

    .border-\[2px\] {
      border-width: 2px;
    }

    .border-y-3p {
      border-top-width: 3px;
      border-bottom-width: 3px;
    }

    .border-b {
      border-bottom-width: 1px;
    }

    .border-brand {
      border-color: #395442;
    }

    .border-b-2 {
      border-bottom-width: 2px;
    }

    .border-b-1p {
      border-bottom-width: 1px;
    }

    .border-b-2p {
      border-bottom-width: 2px;
    }

    .border-b-4 {
      border-bottom-width: 4px;
    }

    .border-l-2p {
      border-left-width: 2px;
    }

    .border-r-0 {
      border-right-width: 0px;
    }

    .border-r-1p {
      border-right-width: 1px;
    }

    .border-r-2p {
      border-right-width: 2px;
    }

    .border-r-3p {
      border-right-width: 3px;
    }

    .border-t-1p {
      border-top-width: 1px;
    }

    .border-t-3p {
      border-top-width: 3px;
    }

    .border-solid {
      border-style: solid;
    }

    .border-dashed {
      border-style: dashed;
    }

    .border-amber-600 {
      --tw-border-opacity: 1;
      border-color: rgb(217 119 6 / var(--tw-border-opacity));
    }

    .border-blue-190 {
      --tw-border-opacity: 1;
      border-color: rgb(24 151 136 / var(--tw-border-opacity));
    }

    .border-blue-550 {
      --tw-border-opacity: 1;
      border-color: rgb(2 88 136 / var(--tw-border-opacity));
    }

    .border-gray-100 {
      --tw-border-opacity: 1;
      border-color: rgb(119 119 119 / var(--tw-border-opacity));
    }

    .border-gray-170 {
      --tw-border-opacity: 1;
      border-color: rgb(46 46 46 / var(--tw-border-opacity));
    }

    .border-gray-200 {
      --tw-border-opacity: 1;
      border-color: rgb(229 231 235 / var(--tw-border-opacity));
    }

    .border-gray-400 {
      --tw-border-opacity: 1;
      border-color: rgb(156 163 175 / var(--tw-border-opacity));
    }

    .border-gray-700 {
      --tw-border-opacity: 1;
      border-color: rgb(55 65 81 / var(--tw-border-opacity));
    }

    .border-gray-900 {
      --tw-border-opacity: 1;
      border-color: rgb(17 24 39 / var(--tw-border-opacity));
    }

    .bg-black {
      --tw-bg-opacity: 1;
      background-color: rgb(0 0 0 / var(--tw-bg-opacity));
    }

    .bg-brand {
      background-color: #395442;
    }

    .bg-blue-190 {
      --tw-bg-opacity: 1;
      background-color: rgb(24 151 136 / var(--tw-bg-opacity));
    }

    .bg-blue-20 {
      --tw-bg-opacity: 1;
      background-color: rgb(238 243 250 / var(--tw-bg-opacity));
    }

    .bg-gray-100 {
      --tw-bg-opacity: 1;
      background-color: rgb(119 119 119 / var(--tw-bg-opacity));
    }

    .bg-gray-10 {
      --tw-bg-opacity: 1;
      background-color: rgb(245 245 245 / var(--tw-bg-opacity));
    }

    .bg-gray-200 {
      --tw-bg-opacity: 1;
      background-color: rgb(229 231 235 / var(--tw-bg-opacity));
    }

    .bg-gray-900 {
      --tw-bg-opacity: 1;
      background-color: rgb(17 24 39 / var(--tw-bg-opacity));
    }

    .bg-green-40 {
      --tw-bg-opacity: 1;
      background-color: rgb(73 245 203 / var(--tw-bg-opacity));
    }

    .bg-green-400 {
      --tw-bg-opacity: 1;
      background-color: rgb(74 222 128 / var(--tw-bg-opacity));
    }

    .bg-green-900 {
      --tw-bg-opacity: 1;
      background-color: rgb(20 83 45 / var(--tw-bg-opacity));
    }

    .bg-green-10 {
      background-color: #e4fde6;
    }

    .bg-red-100 {
      --tw-bg-opacity: 1;
      background-color: rgb(254 226 226 / var(--tw-bg-opacity));
    }

    .bg-transparent {
      background-color: transparent;
    }

    .bg-white {
      --tw-bg-opacity: 1;
      background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    }

    .bg-opacity-50 {
      --tw-bg-opacity: 0.5;
    }

    .bg-gradient-to-br {
      background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
    }

    .bg-gradient-to-r {
      background-image: linear-gradient(to right, var(--tw-gradient-stops));
    }

    .bg-none {
      background-image: none;
    }

    .from-cyan-400 {
      --tw-gradient-from: #22d3ee var(--tw-gradient-from-position);
      --tw-gradient-to: rgb(34 211 238 / 0) var(--tw-gradient-to-position);
      --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    }

    .to-purple-400 {
      --tw-gradient-to: #c084fc var(--tw-gradient-to-position);
    }

    .to-sky-300 {
      --tw-gradient-to: #7dd3fc var(--tw-gradient-to-position);
    }

    .object-contain {
      object-fit: contain;
    }

    .object-fill {
      object-fit: fill;
    }

    .p-1 {
      padding: 0.25rem;
    }

    .p-8 {
      padding: 1rem;
    }

    .p-12 {
      padding: 1.5rem;
    }

    .object-fill {
      object-fit: fill;
    }

    .object-cover {
      object-fit: cover;
    }

    .p-2 {
      padding: 0.5rem;
    }

    .p-4 {
      padding: 1rem;
    }

    .px-1 {
      padding-left: 0.25rem;
      padding-right: 0.25rem;
    }

    .px-2 {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    .px-24 {
      padding-left: 6rem;
      padding-right: 6rem;
    }

    .px-3 {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }

    .px-3p {
      padding-left: 3px;
      padding-right: 3px;
    }

    .px-4 {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .px-5 {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }

    .px-6 {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    .px-8 {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    .px-\[24em\] {
      padding-left: 24em;
      padding-right: 24em;
    }

    .px-\[2em\] {
      padding-left: 2em;
      padding-right: 2em;
    }

    .py-1 {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
    }

    .py-2p {
      padding-top: 2px;
      padding-bottom: 2px;
    }

    .py-10p {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .py-2 {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    .py-2\.5 {
      padding-top: 0.625rem;
      padding-bottom: 0.625rem;
    }

    .py-3 {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
    }

    .py-3p {
      padding-top: 3px;
      padding-bottom: 3px;
    }

    .py-4 {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    .py-6 {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }

    .py-8 {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }

    .py-\[-6em\] {
      padding-top: -6em;
      padding-bottom: -6em;
    }

    .py-\[1em\] {
      padding-top: 1em;
      padding-bottom: 1em;
    }

    .py-\[6em\] {
      padding-top: 6em;
      padding-bottom: 6em;
    }

    .pb-1 {
      padding-bottom: 0.25rem;
    }

    .pb-6 {
      padding-bottom: 1.5rem;
    }

    .pb-8 {
      padding-bottom: 2rem;
    }

    .pb-16 {
      padding-bottom: 4rem;
    }

    .pl-4 {
      padding-left: 1rem;
    }

    .pl-8 {
      padding-left: 2rem;
    }

    .pr-0 {
      padding-right: 0px;
    }

    .pr-2 {
      padding-right: 0.5rem;
    }

    .pt-1 {
      padding-top: 0.25rem;
    }

    .pt-2 {
      padding-top: 0.5rem;
    }

    .pt-20 {
      padding-top: 5rem;
    }

    .pt-3 {
      padding-top: 0.75rem;
    }

    .pt-6 {
      padding-top: 1.5rem;
    }

    .pt-8 {
      padding-top: 2rem;
    }

    .text-left {
      text-align: left;
    }

    .text-center {
      text-align: center;
    }

    .font-sans {
      font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    }

    .text-12p {
      font-size: 12px;
    }

    .text-13p {
      font-size: 13px;
    }

    .text-14p {
      font-size: 14px;
    }

    .text-15p {
      font-size: 15px;
    }

    .text-16p {
      font-size: 16px;
    }

    .text-17p {
      font-size: 17px;
    }

    .text-18p {
      font-size: 18px;
    }

    .text-20p {
      font-size: 20px;
    }

    .text-22p {
      font-size: 22px;
    }

    .text-24p {
      font-size: 24px;
    }

    .text-28p {
      font-size: 28px;
    }

    .text-2xl {
      font-size: 1.5rem;
      line-height: 2rem;
    }

    .text-30p {
      font-size: 30px;
    }

    .text-36p {
      font-size: 36px;
    }

    .text-48p {
      font-size: 48px;
    }

    .text-8xl {
      font-size: 6rem;
      line-height: 1;
    }

        .text-7xl {
          font-size: 5rem;
          line-height: 1;
        }

    .text-6xl {
      font-size: 4rem;
      line-height: 1;
    }

    .text-5xl {
      font-size: 3.5rem;
      line-height: 1;
    }

    .text-4xl {
      font-size: 3rem;
      line-height: 1;
    }

    .text-3xl {
      font-size: 1.875rem;
      line-height: 2.25rem;
    }

    .text-base {
      font-size: 1rem;
      line-height: 1.5rem;
    }

    .text-lg {
      font-size: 1.125rem;
      line-height: 1.75rem;
    }

    .text-sm {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }

    .text-xl {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }

    .text-xs {
      font-size: 0.75rem;
      line-height: 1rem;
    }

    .font-bold {
      font-weight: 700;
    }

    .font-medium {
      font-weight: 500;
    }

    .font-normal {
      font-weight: 400;
    }

    .font-semibold {
      font-weight: 600;
    }

    .uppercase {
      text-transform: uppercase;
    }

    .leading-none {
      line-height: 1;
    }

    .text-amber-600 {
      --tw-text-opacity: 1;
      color: rgb(217 119 6 / var(--tw-text-opacity));
    }

    .text-black {
      --tw-text-opacity: 1;
      color: rgb(0 0 0 / var(--tw-text-opacity));
    }

    .text-blue-190 {
      --tw-text-opacity: 1;
      color: rgb(24 151 136 / var(--tw-text-opacity));
    }

    .text-blue-200 {
      --tw-text-opacity: 1;
      color: rgb(38 216 196 / var(--tw-text-opacity));
    }

    .text-gray-100 {
      --tw-text-opacity: 1;
      color: rgb(119 119 119 / var(--tw-text-opacity));
    }

    .text-gray-170 {
      --tw-text-opacity: 1;
      color: rgb(46 46 46 / var(--tw-text-opacity));
    }

    .text-gray-200 {
      --tw-text-opacity: 1;
      color: rgb(229 231 235 / var(--tw-text-opacity));
    }

    .text-gray-300 {
      --tw-text-opacity: 1;
      color: rgb(209 213 219 / var(--tw-text-opacity));
    }

    .text-gray-400 {
      --tw-text-opacity: 1;
      color: rgb(156 163 175 / var(--tw-text-opacity));
    }

    .text-gray-500 {
      --tw-text-opacity: 1;
      color: rgb(107 114 128 / var(--tw-text-opacity));
    }

    .text-gray-600 {
      --tw-text-opacity: 1;
      color: rgb(75 85 99 / var(--tw-text-opacity));
    }

    .text-gray-70 {
      --tw-text-opacity: 1;
      color: rgb(170 170 170 / var(--tw-text-opacity));
    }

    .text-gray-700 {
      --tw-text-opacity: 1;
      color: rgb(55 65 81 / var(--tw-text-opacity));
    }

    .text-gray-900 {
      --tw-text-opacity: 1;
      color: rgb(17 24 39 / var(--tw-text-opacity));
    }

    .text-red-50 {
      --tw-text-opacity: 1;
      color: rgb(217 83 79 / var(--tw-text-opacity));
    }

    .text-white {
      --tw-text-opacity: 1;
      color: rgb(255 255 255 / var(--tw-text-opacity));
    }

    .text-green-100 {
      color: #069e39;
    }

    .text-brand {
      color: #395442;
    }

    .opacity-100 {
      opacity: 1;
    }

    .opacity-40 {
      opacity: 0.4;
    }

    .shadow-md {
      --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
      --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }

    .outline {
      outline-style: solid;
    }

    .ring-0 {
      --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
      --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
      box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
    }

    .ring-white {
      --tw-ring-opacity: 1;
      --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity));
    }

    .blur-\[200px\] {
      --tw-blur: blur(200px);
      filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
    }

    .backdrop-blur-sm {
      --tw-backdrop-blur: blur(4px);
      -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
      backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    }

    .transition {
      transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
      transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
      transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;
    }

    .scrollbar {
      scrollbar-width: thin;
      scrollbar-color: #b3b3b3 transparent;
    }

    .scrollbar::-webkit-scrollbar {
      height: 4px;
      width: 4px;
    }

    .scrollbar::-webkit-scrollbar-thumb {
      border-radius: 5px;
      border: 5px solid #b3b3b3;
    }

    .scrollbar::-webkit-scrollbar-track {
      background-color: transparent;
    }

    .scrollbar::-webkit-scrollbar-thumb:hover {
      background-color: #bbbbbb;
    }

    :root {
      --main-dark-color: #189788;
      --main-light-color: #26D8C4;
      --background-color: linear-gradient(160deg, rgba(240, 242, 245, 1) 0%,
        rgba(209, 223, 255, 1) 51%,
        rgba(213, 225, 255, 1) 100%);
      --card-color: rgb(255, 255, 255);
      --light-dark-font-color: rgb(51, 51, 51);
      --grey-color: grey;
      --logo-color: rgb(0, 145, 255);
    }

    [data-theme="dark"] {
      --background-color: linear-gradient(160deg, rgba(23, 46, 84, 1) 0%,
        rgba(17, 26, 49, 1) 31%,
        rgba(15, 23, 42, 1) 100%);
      --card-color: rgb(35, 35, 35);
      --light-dark-font-color: rgb(204, 204, 204);
    }

    body {
      margin: 0;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
     
      transition: background-image 1s;
      background-image: linear-gradient(-225deg, #FFFEFF 0%, #f0f8f1 100%);
    }

    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }

    .loader {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 160px;
      height: 160px;
      background: transparent;
      border: 3px solid var(--main-light-color);
      border-radius: 50%;
      text-align: center;
      line-height: 20px;
      font-family: sans-serif;
      font-size: 16px;
      color: var(--main-dark-color);
      letter-spacing: 4px;
      text-transform: uppercase;
      text-shadow: 0 0 10px var(--main-dark-color);
      box-shadow: 0 0 20px rgba(12, 176, 235, 0.5);
    }

    .loader .inner {
      display: block;
      position: absolute;
      top: calc(50% - 2px);
      left: 50%;
      width: 50%;
      height: 4px;
      background: transparent;
      transform-origin: left;
      animation: animate 1.3s linear infinite;
    }

    .loader .inner:before {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: var(--main-light-color);
      top: -6px;
      right: -8px;
      box-shadow: 0 0 20px var(--main-light-color);
    }

    @keyframes animateC {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes animate {
      0% {
        transform: rotate(45deg);
      }

      100% {
        transform: rotate(405deg);
      }
    }

    .duration-300 {
      transition-duration: 300ms;
    }

    .before\:absolute::before {
      content: var(--tw-content);
      position: absolute;
    }

    .before\:inset-0::before {
      content: var(--tw-content);
      inset: 0px;
    }

    .before\:cursor-pointer::before {
      content: var(--tw-content);
      cursor: pointer;
    }

    .before\:rounded-full::before {
      content: var(--tw-content);
      border-radius: 9999px;
    }

    .before\:rounded-lg::before {
      content: var(--tw-content);
      border-radius: 0.5rem;
    }

    .before\:rounded-md::before {
      content: var(--tw-content);
      border-radius: 0.375rem;
    }

    .before\:border-2p::before {
      content: var(--tw-content);
      border-width: 2px;
    }

    .before\:border-3p::before {
      content: var(--tw-content);
      border-width: 3px;
    }

    .before\:border-blue-500::before {
      content: var(--tw-content);
      --tw-border-opacity: 1;
      border-color: rgb(15 23 42 / var(--tw-border-opacity));
    }

    .before\:border-gray-700::before {
      content: var(--tw-content);
      --tw-border-opacity: 1;
      border-color: rgb(55 65 81 / var(--tw-border-opacity));
    }

    .before\:border-green-500::before {
      content: var(--tw-content);
      --tw-border-opacity: 1;
      border-color: rgb(34 197 94 / var(--tw-border-opacity));
    }

    .before\:border-red-500::before {
      content: var(--tw-content);
      --tw-border-opacity: 1;
      border-color: rgb(239 68 68 / var(--tw-border-opacity));
    }

    .before\:bg-green-400::before {
      content: var(--tw-content);
      --tw-bg-opacity: 1;
      background-color: rgb(74 222 128 / var(--tw-bg-opacity));
    }

    .before\:bg-red-100::before {
      content: var(--tw-content);
      --tw-bg-opacity: 1;
      background-color: rgb(254 226 226 / var(--tw-bg-opacity));
    }

    .before\:bg-gradient-to-b::before {
      content: var(--tw-content);
      background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
    }

    .before\:transition::before {
      content: var(--tw-content);
      transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
      transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
      transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;
    }

    .before\:duration-200::before {
      content: var(--tw-content);
      transition-duration: 200ms;
    }

    .before\:duration-300::before {
      content: var(--tw-content);
      transition-duration: 300ms;
    }

    .hover\:scale-105:hover {
      --tw-scale-x: 1.05;
      --tw-scale-y: 1.05;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }

    .hover\:border-blue-200:hover {
      --tw-border-opacity: 1;
      border-color: rgb(38 216 196 / var(--tw-border-opacity));
    }

    .hover\:bg-black:hover {
      --tw-bg-opacity: 1;
      background-color: rgb(0 0 0 / var(--tw-bg-opacity));
    }

    .hover\:bg-blue-200:hover {
      --tw-bg-opacity: 1;
      background-color: rgb(38 216 196 / var(--tw-bg-opacity));
    }

    .hover\:bg-gray-100:hover {
      --tw-bg-opacity: 1;
      background-color: rgb(119 119 119 / var(--tw-bg-opacity));
    }

    .hover\:bg-gray-200:hover {
      --tw-bg-opacity: 1;
      background-color: rgb(229 231 235 / var(--tw-bg-opacity));
    }

    .hover\:px-2:hover {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    .hover\:text-blue-190:hover {
      --tw-text-opacity: 1;
      color: rgb(24 151 136 / var(--tw-text-opacity));
    }

    .hover\:text-blue-700:hover {
      --tw-text-opacity: 1;
      color: rgb(10 15 28 / var(--tw-text-opacity));
    }

    .hover\:text-gray-900:hover {
      --tw-text-opacity: 1;
      color: rgb(17 24 39 / var(--tw-text-opacity));
    }

    .hover\:text-white:hover {
      --tw-text-opacity: 1;
      color: rgb(255 255 255 / var(--tw-text-opacity));
    }

    .hover\:before\:scale-105:hover::before {
      content: var(--tw-content);
      --tw-scale-x: 1.05;
      --tw-scale-y: 1.05;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }

    .before\:hover\:border-blue-500:hover::before {
      content: var(--tw-content);
      --tw-border-opacity: 1;
      border-color: rgb(15 23 42 / var(--tw-border-opacity));
    }

    .focus\:z-10:focus {
      z-index: 10;
    }

    .focus\:text-blue-700:focus {
      --tw-text-opacity: 1;
      color: rgb(10 15 28 / var(--tw-text-opacity));
    }

    .focus\:outline-none:focus {
      outline: 2px solid transparent;
      outline-offset: 2px;
    }

    .focus\:ring-4:focus {
      --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
      --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
      box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
    }

    .focus\:ring-blue-700:focus {
      --tw-ring-opacity: 1;
      --tw-ring-color: rgb(10 15 28 / var(--tw-ring-opacity));
    }

    .active\:duration-75:active {
      transition-duration: 75ms;
    }

    .active\:before\:scale-95:active::before {
      content: var(--tw-content);
      --tw-scale-x: .95;
      --tw-scale-y: .95;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }

    :is([data-theme="dark"] .dark\:border-1p) {
      border-width: 1px;
    }

    :is([data-theme="dark"] .dark\:border-2p) {
      border-width: 2px;
    }

    :is([data-theme="dark"] .dark\:border-3p) {
      border-width: 3px;
    }

    :is([data-theme="dark"] .dark\:border-solid) {
      border-style: solid;
    }

    :is([data-theme="dark"] .dark\:border-none) {
      border-style: none;
    }

    :is([data-theme="dark"] .dark\:border-blue-600) {
      --tw-border-opacity: 1;
      border-color: rgb(0 32 113 / var(--tw-border-opacity));
    }

    :is([data-theme="dark"] .dark\:border-gray-10) {
      --tw-border-opacity: 1;
      border-color: rgb(235 235 242 / var(--tw-border-opacity));
    }

    :is([data-theme="dark"] .dark\:border-gray-50) {
      --tw-border-opacity: 1;
      border-color: rgb(249 250 251 / var(--tw-border-opacity));
    }

    :is([data-theme="dark"] .dark\:border-gray-600) {
      --tw-border-opacity: 1;
      border-color: rgb(75 85 99 / var(--tw-border-opacity));
    }

    :is([data-theme="dark"] .dark\:border-gray-700) {
      --tw-border-opacity: 1;
      border-color: rgb(55 65 81 / var(--tw-border-opacity));
    }

    :is([data-theme="dark"] .dark\:border-white) {
      --tw-border-opacity: 1;
      border-color: rgb(255 255 255 / var(--tw-border-opacity));
    }

    :is([data-theme="dark"] .dark\:bg-blue-300) {
      --tw-bg-opacity: 1;
      background-color: rgb(10 10 10 / var(--tw-bg-opacity));
    }

    :is([data-theme="dark"] .dark\:bg-blue-400) {
      --tw-bg-opacity: 1;
      background-color: rgb(23 46 84 / var(--tw-bg-opacity));
    }

    :is([data-theme="dark"] .dark\:bg-blue-450) {
      --tw-bg-opacity: 1;
      background-color: rgb(17 26 49 / var(--tw-bg-opacity));
    }

    :is([data-theme="dark"] .dark\:bg-blue-500) {
      --tw-bg-opacity: 1;
      background-color: rgb(15 23 42 / var(--tw-bg-opacity));
    }

    :is([data-theme="dark"] .dark\:bg-blue-900) {
      --tw-bg-opacity: 1;
      background-color: rgb(30 58 138 / var(--tw-bg-opacity));
    }

    :is([data-theme="dark"] .dark\:bg-gray-700) {
      --tw-bg-opacity: 1;
      background-color: rgb(55 65 81 / var(--tw-bg-opacity));
    }

    :is([data-theme="dark"] .dark\:bg-gray-800) {
      --tw-bg-opacity: 1;
      background-color: rgb(31 41 55 / var(--tw-bg-opacity));
    }

    :is([data-theme="dark"] .dark\:bg-green-80) {
      --tw-bg-opacity: 1;
      background-color: rgb(7 132 102 / var(--tw-bg-opacity));
    }

    :is([data-theme="dark"] .dark\:bg-white) {
      --tw-bg-opacity: 1;
      background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    }

    :is([data-theme="dark"] .dark\:bg-gradient-to-r) {
      background-image: linear-gradient(to right, var(--tw-gradient-stops));
    }

    :is([data-theme="dark"] .dark\:from-blue-600) {
      --tw-gradient-from: #002071 var(--tw-gradient-from-position);
      --tw-gradient-to: rgb(0 32 113 / 0) var(--tw-gradient-to-position);
      --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    }

    :is([data-theme="dark"] .dark\:from-blue-700) {
      --tw-gradient-from: #0a0f1c var(--tw-gradient-from-position);
      --tw-gradient-to: rgb(10 15 28 / 0) var(--tw-gradient-to-position);
      --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    }

    :is([data-theme="dark"] .dark\:to-blue-550) {
      --tw-gradient-to: #025888 var(--tw-gradient-to-position);
    }

    :is([data-theme="dark"] .dark\:to-indigo-600) {
      --tw-gradient-to: #4f46e5 var(--tw-gradient-to-position);
    }

    :is([data-theme="dark"] .dark\:text-gray-300) {
      --tw-text-opacity: 1;
      color: rgb(209 213 219 / var(--tw-text-opacity));
    }

    :is([data-theme="dark"] .dark\:text-gray-400) {
      --tw-text-opacity: 1;
      color: rgb(156 163 175 / var(--tw-text-opacity));
    }

    :is([data-theme="dark"] .dark\:text-gray-500) {
      --tw-text-opacity: 1;
      color: rgb(107 114 128 / var(--tw-text-opacity));
    }

    :is([data-theme="dark"] .dark\:text-gray-600) {
      --tw-text-opacity: 1;
      color: rgb(75 85 99 / var(--tw-text-opacity));
    }

    :is([data-theme="dark"] .dark\:text-gray-700) {
      --tw-text-opacity: 1;
      color: rgb(55 65 81 / var(--tw-text-opacity));
    }

    :is([data-theme="dark"] .dark\:text-white) {
      --tw-text-opacity: 1;
      color: rgb(255 255 255 / var(--tw-text-opacity));
    }

    :is([data-theme="dark"] .dark\:opacity-20) {
      opacity: 0.2;
    }

    :is([data-theme="dark"] .dark\:ring-gray-900) {
      --tw-ring-opacity: 1;
      --tw-ring-color: rgb(17 24 39 / var(--tw-ring-opacity));
    }

    :is([data-theme="dark"] .dark\:before\:border-gray-700)::before {
      content: var(--tw-content);
      --tw-border-opacity: 1;
      border-color: rgb(55 65 81 / var(--tw-border-opacity));
    }

    :is([data-theme="dark"] .dark\:before\:border-green-700)::before {
      content: var(--tw-content);
      --tw-border-opacity: 1;
      border-color: rgb(21 128 61 / var(--tw-border-opacity));
    }

    :is([data-theme="dark"] .dark\:before\:border-red-700)::before {
      content: var(--tw-content);
      --tw-border-opacity: 1;
      border-color: rgb(185 28 28 / var(--tw-border-opacity));
    }

    :is([data-theme="dark"] .dark\:before\:border-white)::before {
      content: var(--tw-content);
      --tw-border-opacity: 1;
      border-color: rgb(255 255 255 / var(--tw-border-opacity));
    }

    :is([data-theme="dark"] .dark\:before\:bg-gray-800)::before {
      content: var(--tw-content);
      --tw-bg-opacity: 1;
      background-color: rgb(31 41 55 / var(--tw-bg-opacity));
    }

    :is([data-theme="dark"] .dark\:before\:bg-green-900)::before {
      content: var(--tw-content);
      --tw-bg-opacity: 1;
      background-color: rgb(20 83 45 / var(--tw-bg-opacity));
    }

    :is([data-theme="dark"] .dark\:before\:bg-red-400)::before {
      content: var(--tw-content);
      --tw-bg-opacity: 1;
      background-color: rgb(248 113 113 / var(--tw-bg-opacity));
    }

    :is([data-theme="dark"] .dark\:hover\:bg-gray-600:hover) {
      --tw-bg-opacity: 1;
      background-color: rgb(75 85 99 / var(--tw-bg-opacity));
    }

    :is([data-theme="dark"] .dark\:hover\:bg-gray-700:hover) {
      --tw-bg-opacity: 1;
      background-color: rgb(55 65 81 / var(--tw-bg-opacity));
    }

    :is([data-theme="dark"] .dark\:hover\:text-white:hover) {
      --tw-text-opacity: 1;
      color: rgb(255 255 255 / var(--tw-text-opacity));
    }

    :is([data-theme="dark"] .dark\:hover\:before\:border-green-700:hover)::before {
      content: var(--tw-content);
      --tw-border-opacity: 1;
      border-color: rgb(21 128 61 / var(--tw-border-opacity));
    }

    :is([data-theme="dark"] .dark\:hover\:before\:border-red-700:hover)::before {
      content: var(--tw-content);
      --tw-border-opacity: 1;
      border-color: rgb(185 28 28 / var(--tw-border-opacity));
    }

    .skew-y-2 {
      transform: skewY(2deg);
    }

    @media (max-width: 640px) {

      .sm\:mb-0 {
        margin-bottom: 0px;
      }

      .sm\:flex {
        display: flex;
      }

      .sm\:w-max {
        width: max-content;
      }

      .sm\:pe-8 {
        padding-inline-end: 2rem;
      }

      .sm\:text-10p {
        font-size: 10px;
      }

      .sm\:ring-8 {
        --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
        --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
        box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
      }
    }

    @media (max-width: 980px) {

      .md\:inline {
        display: inline;
      }

      .md\:w-auto {
        width: auto;
      }

      .md\:p-5 {
        padding: 1.25rem;
      }

      .md\:text-12p {
        font-size: 12px;
      }

      .md\:text-14p {
        font-size: 14px;
      }

      .md\:text-6xl {
        font-size: 3.75rem;
        line-height: 1;
      }
    }

    @media (max-width: 1080px) {

      .lg\:w-2\/3 {
        width: 66.666667%;
      }

      .lg\:px-8 {
        padding-left: 2rem;
        padding-right: 2rem;
      }

      .lg\:py-4 {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }

    @media (max-width: 1200px) {

      .xl\:text-7xl {
        font-size: 4.5rem;
        line-height: 1;
      }
    }

    @media (max-width: 1280px) {

      .xxl\:text-16p {
        font-size: 16px;
      }
    }

    .rtl\:text-right:where([dir="rtl"], [dir="rtl"] *) {
      text-align: right;
    }

    