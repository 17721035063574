
.navbar-brand-bold {
    font-weight: 700;
}

.nav-link-bold {
    font-weight: bold;
    color: black;
    transition: color 0.3s;
}

.nav-link-bold:hover {
    color: green;
}

.mr-1 {
    margin-right: 0.25rem;
}

.title-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
    width: 70%;
    font-weight: bold;
    border-radius: 8px;
    z-index: 2;
    text-align: center;
    padding: 2rem;
    color: #fff;
    background-color: rgba(57, 84, 66, 0.5);
    backdrop-filter: blur(2px);
}

.custom-button {
    font-size: 18px;
    pointer-events: auto;
    border: none;
    margin: 0 3em 0 0 3em;
    background-color: #395442;
    color: white;
}

.custom-button:hover {
    background-color: #2f4538;
}

.flex {
    display: flex;
}

.screen-container {
    height: 100vh;
    width: 100vw;
}

.bg-brand {
    background-color: #395442;
}


.title-overlay .title {
    margin-bottom: 0.5rem;
   
    font-size: 1rem;
   
}

@media (min-width: 640px) {
    .title-overlay .title {
        font-size: 1.25rem;
       
    }
}

@media (min-width: 768px) {
    .title-overlay .title {
        font-size: 1.5rem;
       
    }
}

@media (min-width: 1024px) {
    .title-overlay .title {
        font-size: 1.5rem;
       
    }
}


.title-overlay .subtitle {
    margin-bottom: 1.25rem;
   
    font-size: 1.9rem;
   
    font-weight: bold;
   
}

@media (max-width: 450px) {
    .title-overlay .subtitle {
        font-size: 1.7rem;
       
    }
}

@media (min-width: 640px) {
    .title-overlay .subtitle {
        font-size: 2.6rem;
       
    }
}

@media (min-width: 768px) {
    .title-overlay .subtitle {
        font-size: 3.6rem;
       
    }
}

@media (min-width: 1024px) {
    .title-overlay .subtitle {
        font-size: 4rem;
       
    }
}


.title-overlay .button-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
   
}

@media (min-width: 640px) {
    .title-overlay .button-group {
        flex-direction: row;
       
    }
}

.title-overlay .button {
    background-color: #395442;
   
    color: white;
   
    padding: 0.5rem 1rem;
   
    border-radius: 0.5rem;
   
    font-size: 1rem;
   
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: transform 0.2s ease, text-decoration 0.2s ease;
}

.title-overlay .button:hover {
    transform: scale(1.05);
   
    text-decoration: none;
   
}

@media (min-width: 640px) {
    .title-overlay .button{
        font-size: 0.7rem;
       
        padding: 0.5rem 0.7rem;
    }
}

@media (min-width: 768px) {
    .title-overlay .button{
        font-size: 0.8rem;
       
        padding: 0.5rem 0.75rem;
    }
}

@media (min-width: 1024px) {
    .title-overlay .button{
        font-size: 0.9rem;
       
        padding: 0.5rem 1rem;
        padding: 0.5rem 0.9rem;
    }
}

@media (max-width: 640px) {
    .title-overlay .button {
        font-size: 0.7rem;
       
        padding: 0.5rem 0.7rem;
    }
}

.model-features-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 1rem;
}

.feature-item {
    width: calc(50% - 1rem);
    margin-bottom: 4rem;
}


.feature-item:nth-last-child(-n+2) {
    margin-bottom: -2rem;
}

@media (max-width: 768px) {
    .feature-item {
        width: 100%;
        margin-bottom: 2rem;
    }

    .feature-item:nth-last-child(-n+2) {
        margin-bottom: 2rem;
    }

    .feature-item:last-child {
        margin-bottom: 0;
    }
}

.publication-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: 400px;
    width: 100%;
    height: 100%;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
    margin-bottom: 16px;
    height: 500px;
}

.publication-card:hover {
    transform: scale(1.05);
}


.publication-card-content {
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 425px;
    overflow-y: scroll;
    border-bottom: 1px solid rgb(216, 212, 212);
}

.publication-card-title {
    font-size: 1.25rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 8px;
}

.publication-card-description {
    list-style-type: disc;
    padding-left: 16px;
    margin-bottom: 16px;
}

.publication-card-description li {
    font-size: 0.875rem;
    color: #555;
    margin-bottom: 4px;
}


.publications-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6rem;    
    padding: 16px;
    max-width: 786px;
}

.publications-container>* {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1;
    max-width: 30%;
    min-width: 300px;
    box-sizing: border-box;
    margin-bottom: 16px;
}

@media (min-width: 768px) and (max-width: 1027px) {
    .publications-container>* {
        flex: 1 1 45%;
        max-width: 40%;
    }
}

@media (max-width: 767px) {
    .publications-container {
        flex-direction: column;
    }

    .publications-container>* {
        flex: 1 1 100%;
        max-width: 300px;
    }
}

.app-features-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px;
    padding: 8px;
}

.app-feature {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex: 1 1 30%;
    max-width: 30%;
    min-width: 250px;
    padding: 16px;
    border-radius: 12px;
}

.feature-title {
    margin-top: 16px;
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
}

.feature-description {
    margin-top: 8px;
    font-size: 1rem;
    color: #666;
}

@media (min-width: 1028px) {
    .app-feature {
        flex: 1 1 30%;
    }
}

@media (max-width: 1028px) and (min-width: 768px) {
    .app-feature {
        flex: 1 1 45%;
    }
}

@media (max-width: 768px) {
    .app-feature {
        flex: 1 1 100%;
    }
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: auto;
}

.footer-logo {
    flex: 1;
}

.footer-links {
    display: flex;
    justify-content: center;
    gap: 32px;
}

.footer-section {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: #ddd;
    margin: 16px 0;
}

@media (max-width: 748px) {
    .footer-content {
        flex-direction: column;
        align-items: center;
    }

    .footer-links {
        flex-direction: column;
        gap: 16px;
        margin-top: 16px;
    }

    .footer-section {
        margin-left: 0;
    }

    .footer-logo {
        margin-bottom: 16px;
    }
}

.wave-background {
  position: relative;
  background-image: linear-gradient(109.6deg, rgba(61, 131, 97, 1) 11.2%, rgba(28, 103, 88, 1) 91.1%);
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;
  overflow: hidden;
}

.wave-background::before,
.wave-background::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 100px;
  pointer-events: none;
  z-index: 2;
}

.wave-background::before {
  top: -100px;
  background: linear-gradient(to bottom, 
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.8) 20%,
    rgba(255, 255, 255, 0.6) 40%,
    rgba(255, 255, 255, 0.4) 60%,
    rgba(255, 255, 255, 0.2) 80%,
    rgba(255, 255, 255, 0) 100%
  );
}

.wave-background::after {
  bottom: -100px;
  background: linear-gradient(to top, 
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.8) 20%,
    rgba(255, 255, 255, 0.6) 40%,
    rgba(255, 255, 255, 0.4) 60%,
    rgba(255, 255, 255, 0.2) 80%,
    rgba(255, 255, 255, 0) 100%
  );
}

.wave-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.wave-content {
  position: relative;
  z-index: 3;
  padding: 50px 0;
}

.wave {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 1000% 1000% 0 0;
  position: absolute;
  width: 200%;
  height: 12em;
  animation: wave 8s -3s linear infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.7;
  bottom: 0;
  left: 0;
}

.wave-2 {
  bottom: -1.25em;
  animation: wave 18s linear reverse infinite;
  opacity: 0.5;
}

.wave-3 {
  bottom: -2.5em;
  animation: wave 20s -1s reverse infinite;
  opacity: 0.3;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes wave {
  2% {
    transform: translateX(1);
  }
  25% {
    transform: translateX(-25%);
  }
  50% {
    transform: translateX(-50%);
  }
  75% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(1);
  }
}

.cookie-consent-button {
    border: 2px solid #395442;
    background-color: white;
    color: #395442;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    transition: all 0.3s ease-in-out;
    margin-left: 1rem;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cookie-consent-button span {
    position: relative;
    z-index: 1;
}

.cookie-consent-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #395442;
    transition: all 0.3s ease-out;
}

.cookie-consent-button:hover::before {
    width: 100%;
}

.cookie-consent-button:hover {
    color: white;
}

.bg-brand-grad-green {
background-image: linear-gradient(109.6deg, rgba(61, 131, 97, 1) 11.2%, rgba(28, 103, 88, 1) 91.1%);
}
